.pdf-viewer-icon-button {
  --background: none;
  --border-width: 0px;

  --box-shadow: 0px;
  --padding-start: 0px;
  --padding-end: 0px;
}

.pdf-viewer-fc-haeckchen-button {
  margin-top: -4px;
}

.pdf-viewer-fc-geprueft-button {
  margin-top: -2px;
}
