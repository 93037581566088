.textvorlage-quick-select-container {
  display: flex;
  justify-content: center;
}

.textvorlage-quick-select::part(label) {
  margin: 0 !important;
}

.textvorlage-quick-select::part(text) {
  display: none !important;
}
.textvorlage-quick-select::part(icon) {
  display: none !important;
}
